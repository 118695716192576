<template>
  <div v-if="variant != -1" class="product-notify">
    <h3>Join the waitlist</h3>
    <p>We'll message you when this item is back in stock.</p>
    <form v-if="message == ''" class="form-content" method="get" @submit.prevent="submit($event)">
      <input
        v-model="email"
        type="email"
        name="email"
        placeholder="Email Address"
        size="10"
        maxlength="50"
        required="true"
      />
      <button>
        <SvgArrowNewsletter />
      </button>
    </form>
    <div v-else class="message" v-html="message"></div>
  </div>
</template>

<script>
// const Klaviyo = require('klaviyo-subscribe');
import axios from 'axios'
import qs from 'qs'
import SvgArrowNewsletter from '../svg/SvgArrowNewsletter.vue'
const url = 'https://a.klaviyo.com/onsite/components/back-in-stock/subscribe'

export default {
  components: { SvgArrowNewsletter },
  props: {
    product: Object,
    variant: [Number, Object],
  },
  data() {
    return {
      email: '',
      message: '',
      api_key: 'tVvjMG',
    }
  },
  methods: {
    async submit($evt) {
      try {
        const response = await axios({
          method: 'post',
          url,
          data: qs.stringify({
            a: this.api_key,
            g: null,
            email: this.email,
            product: this.product.id,
            variant: this.variant.id,
            platform: 'shopify',
            subscribe_for_newsletter: true,
          }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;',
          },
        })
        this.message = "You're in! We'll let you know when it's back."
        this.email = ''
      } catch (e) {
        this.message = 'Please enter a valid email address'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-notify {
  max-width: 100%;
  padding-bottom: 30px;
  h3 {
    @include cx-uc;
  }
  > p {
    padding-top: 0.5em;
    padding-bottom: 5px;
    color: $midgrey;
  }

  .form-content {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $dark;
    input {
      width: 100%;
      &::placeholder {
        color: $dark;
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
    }
  }
}
</style>
