<template>
  <a class="global-slide-community" :href="slide.link">
    <ShopifyAccentuateImage
      class="global-slide-community__image"
      :src="slide.image.src"
      :alt="slide.image.alt"
      ratio="3x4"
    />
    <div class="global-slide-community__caption">{{ slide.caption }}</div>
  </a>
</template>

<script>
import ShopifyAccentuateImage from '../shopify/ShopifyAccentuateImage.vue'

export default {
  components: {
    ShopifyAccentuateImage,
  },
  props: {
    slide: Object,
  },
  mounted() {
    console.log(this.slide)
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.global-slide-community {
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s ease;

  @include hover {
    opacity: 0.8;
  }

  &__image {
    margin-bottom: 10px;
  }

  &__caption {
    @include sans;
    /* @include cx-uc; */
    @include cx-xs;
  }
}
</style>
